import cpu from "../../../assets/svg-icons/blueCPU.svg";
import ram from "../../../assets/svg-icons/ram.svg";
import hdd from "../../../assets/svg-icons/disk.svg";
import { useState, useEffect } from "react";
import Input from "../../../components/input";
import Select from "../../../components/select";
import ubuntu from "../../../assets/svg-icons/Ubuntu logo.svg";
import debian from "../../../assets/svg-icons/debian logo.svg";
import rocky from "../../../assets/svg-icons/rocky logo.svg";
import centOS from "../../../assets/svg-icons/centOS logo.svg";
import openSUSE from "../../../assets/svg-icons/openSUSE logo.svg";
import fedora from "../../../assets/svg-icons/fedora logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { listImages } from "../../../hooks/local/userReducer";
import { convertAmountToNumber } from "../../../hooks/constants";

const ConfigureVPS = () => {
  const dispatch = useDispatch();
  const { images = {} } = useSelector((state) => state.user);
  const [cartItems, setCartItems] = useState([]);
  const [selected, setSelected] = useState("1 Month");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");

  const network = "5236d605-3714-41f8-b1c1-6247d3f15cdb";
  const flavorRef = cartItems[0]?.productId;
  const imageRef = selectedValue;

  useEffect(() => {
    const cart = localStorage.getItem("cart");
    if (cart) {
      setCartItems((prevCartItems) => JSON.parse(cart));
    }
  }, []);

  const calculateTotalPrice = (cartItems) => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      const price = parseFloat(item.price.replace("NGN", "").replace(",", ""));
      totalPrice += price;
    });
    return totalPrice;
  };

  let totalPrice = calculateTotalPrice(cartItems).toLocaleString();
  useEffect(() => {
    dispatch(listImages());
  }, [dispatch]);

  const handleSelect = (option) => {
    setSelected(option);
  };

  const activeStyle =
    "text-sm bg-primaryBlue/10 rounded p-4 md:p-5 border border-primaryBlue cursor-pointer";
  const inactiveStyle =
    "text-sm bg-[#c4c4c416] rounded p-4 md:p-5 border dark:border-[#c4c4c424] cursor-pointer";

  const formatOptions = (data = []) => {
    return data.map((item) => ({
      value: item.id,
      label: `${item.os_distro ? `${item.os_distro} - ` : ""}${item.name}`,
    }));
  };

  const ubuntuOptions = formatOptions(images.ubuntu);
  const debianOptions = formatOptions(images.debian);
  const rockyOptions = formatOptions(images.rocky);
  const centosOptions = formatOptions(images.centos);
  const opensuseOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("openSUSE")) || []
  );
  const fedoraOptions = formatOptions(images["fedora-coreos"]);
  const otherUbuntuOptions = formatOptions(
    images.others?.filter((item) => item.name.includes("Ubuntu")) || []
  );

  const allUbuntuOptions = [...ubuntuOptions, ...otherUbuntuOptions];

  const getOptionsForSelectedImage = () => {
    switch (selectedImage) {
      case "ubuntu":
        return allUbuntuOptions;
      case "debian":
        return debianOptions;
      case "rocky":
        return rockyOptions;
      case "centos":
        return centosOptions;
      case "opensuse":
        return opensuseOptions;
      case "fedora":
        return fedoraOptions;
      default:
        return [];
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setSelectedValue("");
  };

  const handleSelectedValue = (event) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
  };

  const handleConfigureClick = () => {
    console.log("Selected Image:", selectedImage);
    console.log("Selected Option Value (id):", selectedValue);
  };

  return (
    <div className="px-4 md:px-10 pt-6 pb-16 min-h-screen">
      <p className="crimson-font text-xl font-medium text-primaryBlue mb-4">
        {" "}
        Configure your VPS.{" "}
      </p>
      {cartItems.length > 0 ? (
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12 lg:col-span-8 border dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800 rounded-md overflow-clip pb-6">
            <div className="border-b dark:border-b-[#c4c4c424] px-4 py-5 flex justify-between items-center text-lg crimson-font">
              <span>{cartItems.length > 0 ? cartItems[0].name : ""}</span>
              <span className="font-semibold text-[#1da1fc]">
                {" "}
                {cartItems.length > 0
                  ? cartItems[0].price.replace("NGN ", "")
                  : ""}{" "}
                <span className="text-sm font-normal text-gray-600">
                  {" "}
                  /month
                </span>
              </span>
            </div>
            <div className="p-4 border-b dark:border-b-[#c4c4c424] bg-[#c4c4c410] grid grid-cols-3 text-xs">
              <div className="flex items-center gap-2">
                <img src={cpu} alt="" className="h-4" />
                <span>
                  VCPUs: {cartItems.length > 0 ? cartItems[0].vcpu : ""}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img src={ram} alt="" className="h-4" />
                <span>
                  RAM: {cartItems.length > 0 ? cartItems[0].ramSize : ""}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img src={hdd} alt="" className="h-4" />
                <span>
                  Root disk: {cartItems.length > 0 ? cartItems[0].diskSize : ""}
                </span>
              </div>
            </div>
            <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
              <div className="p-4">
                <p className="text-sm">1. Select term length</p>
                <div className="grid grid-cols-1 md:grid-cols-3 mt-2 gap-4">
                  <div
                    onClick={() => handleSelect("1 Month")}
                    className={
                      selected === "1 Month" ? activeStyle : inactiveStyle
                    }
                  >
                    <span>1 Month</span>
                  </div>
                  <div
                    onClick={() => handleSelect("1 Year")}
                    className={
                      selected === "1 Year" ? activeStyle : inactiveStyle
                    }
                  >
                    <span className="flex gap-3 items-center">
                      <span>1 Year</span>
                      <span className="text-xs bg-black text-white p-1 rounded">
                        1% discount
                      </span>
                    </span>
                  </div>
                  <p className="hidden">Selected: {selected}</p>
                </div>
              </div>
            </div>
            <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
              <div className="p-4">
                <p className="text-sm">2. Extra storage</p>
                <div className="mt-2 grid grid-cols-1 md:grid-cols-2">
                  <div>
                    <Input type="text" placeholder="e.g 32GB" />
                    <span className="text-gray-500 text-xs mt-2">
                      You can only buy extra storage of 32GB
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b dark:border-[#c4c4c424] dark:text-white bg-white dark:bg-slate-800">
              <div className="p-4">
                <p className="text-sm">3. Images</p>
                <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6">
                  <div
                    className="dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer"
                    onClick={() => handleImageClick("ubuntu")}
                  >
                    <div className="flex justify-center mb-4"><img src={ubuntu} alt="Ubuntu" className="h-10" /></div>
                    <p className="text-center">Ubuntu</p>
                  </div>
                  <div
                    className="dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer"
                    onClick={() => handleImageClick("debian")}
                  >
                    <div className="flex justify-center mb-4"><img src={debian} alt="Debian" className="h-10" /></div>
                    <p className="text-center">Debian</p>
                  </div>
                  <div
                    className="dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer"
                    onClick={() => handleImageClick("rocky")}
                  >
                    <div className="flex justify-center mb-4"><img src={rocky} alt="Rocky" className="h-10" /></div>
                    <p className="text-center">Rocky</p>
                  </div>
                  <div
                    className="dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer"
                    onClick={() => handleImageClick("centos")}
                  >
                    <div className="flex justify-center mb-4"><img src={centOS} alt="CentOS" className="h-10" /></div>
                    <p className="text-center">CentOS</p>
                  </div>
                  <div
                    className="dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer"
                    onClick={() => handleImageClick("opensuse")}
                  >
                    <div className="flex justify-center mb-4"><img src={openSUSE} alt="openSUSE" className="h-10" /></div>
                    <p className="text-center">openSUSE</p>
                  </div>
                  <div
                    className="dark:bg-white/5 p-4 rounded-md border dark:border-[#c4c4c424] cursor-pointer"
                    onClick={() => handleImageClick("fedora")}
                  >
                    <div className="flex justify-center mb-4"><img src={fedora} alt="Fedora" className="h-10" /></div>
                    <p className="text-center">Fedora</p>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-10">
                  {selectedImage && (
                    <div>
                      <p className="capitalize">{selectedImage}</p>
                      <Select
                        options={[
                          { value: "", label: "Select Image", disabled: true },
                          ...getOptionsForSelectedImage(),
                        ]}
                        value={selectedValue}
                        onChange={handleSelectedValue}
                      />
                    </div>
                  )}

                  <div></div>
                </div>
              </div>
            </div>

            <div className="dark:text-white bg-white dark:bg-slate-800">
              <div className="p-4">
                <p className="text-sm">4. Server username and password</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-3">
                  <Input
                    type="text"
                    label={"Username:"}
                    placeholder="e.g 32GB"
                  />
                  <Input type="password" label={"Password:"} />
                </div>
              </div>
            </div>
            <div className="p-4 mt-4">
              <button
                className="bg-primaryBlue text-sm py-5 px-8 rounded-sm"
                type="button"
                onClick={handleConfigureClick}
              >
                Configure
              </button>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="bg-[#1da1f2] rounded text-white">
              <div className="border-b border-white/30 p-4">
                <span className="font-semibold crimson-font">
                  Order summary
                </span>
              </div>
              <div className="p-4 text-sm grid gap-4">
                <div className="flex justify-between">
                  <p>Items:</p>
                  <p>{cartItems.length}</p>
                </div>

                <div className="flex justify-between">
                  <p>Total:</p>
                  <p className="font-semibold">
                    NGN{" "}
                    {selected === "1 Month"
                      ? convertAmountToNumber(totalPrice)
                      : convertAmountToNumber(totalPrice) * 12}
                  </p>
                </div>
                <div className="mt-4">
                  <button
                    className="text-[#1da1f2] bg-white rounded p-4 text-xs w-full"
                    type="submit"
                  >
                    Place order!
                  </button>
                  <div className="text-center cursor-pointer hover:underline text-sm mt-6">Remove item from cart</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="text-center">
            <p className="text-[#1da1f2] text-xl font-medium crimson-font">
              Your cart is empty!
            </p>
            <p className="dark:text-white/50 text-black/50 text-xs">
              Return to the homepage to select and buy a Flavour
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default ConfigureVPS;
