import dewImage from "../../../assets/svg-icons/dewImage.svg";
import domain from "../../../assets/svg-icons/blueDomain.svg";
import Cooperative from "../../../assets/svg-icons/blueCooperative.svg";
import email from "../../../assets/svg-icons/blueEmail.svg";
import loadBalancer from "../../../assets/svg-icons/blueLoadBalancer.svg";
import arrow from "../../../assets/svg-icons/blueArrow.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import add from "../../../assets/svg-icons/addGreen.svg";


const Resources = () => {

  document.title = "Resources - CloudVantage"

    return ( 
        <div>
        {/* Empty state */}

          

        {/* Empty state */}
        <div className="grid gap-2 mb-10">
          <p className="dark:text-white text-sm">Resources:</p>
          {/* Empty state */}
            {/* <div className="mt-6">
              <p className="text-gray-500 text-xl">You dont have any resources yet</p>
              <button className="text-white p-4 text-sm rounded-md bg-primaryBlue mt-2">
                Create dew
              </button>
            </div> */}
          {/* Empty state */}

          <div className="dark:text-white">
            <div className="bg-white dark:bg-slate-800 px-3 md:px-4 border dark:border-[#c4c4c432] border-b rounded-t-md text-sm flex justify-between items-center">
              <div className="">Active servers (Dews) - <span className="text-gray-500">[2]</span></div>
              <Link to={"/vps"} className="cursor-pointer py-4 px-3 flex items-center gap-2">
                <div className="text-emerald-500">Create dew</div>
                <div><img src={add} alt="" className="h-4"/></div>
              </Link>
            </div>
              <div className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
                <div className="grid grid-cols-2 md:grid-cols-4 justify-between items-center gap-4 py-4 last:border-none border-b dark:border-b-[#c4c4c432]">
                  <div className="col-span-2 text-primaryBlue">VantageCent419</div>
                  <div>206.189.20.419</div>
                  <div className="flex justify-end pe-4">
                    <span className="px-3 py-2 bg-emerald-500 text-xs rounded text-white">Manage</span>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 justify-between items-center gap-4 py-4 last:border-none border-b dark:border-b-[#c4c4c432]">
                  <div className="col-span-2 text-primaryBlue">VantageCent419</div>
                  <div>206.189.20.419</div>
                  <div className="flex justify-end pe-4">
                    <span className="px-3 py-2 bg-emerald-500 text-xs rounded text-white">Manage</span>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 justify-between items-center gap-4 py-4 last:border-none border-b dark:border-b-[#c4c4c432]">
                  <div className="col-span-2 text-primaryBlue">VantageCent419</div>
                  <div>206.189.20.419</div>
                  <div className="flex justify-end pe-4">
                    <span className="px-3 py-2 bg-emerald-500 text-xs rounded text-white">Manage</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="grid gap-4 mb-10">
          <p className="dark:text-white text-sm">Create:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Link to={"/vps"} className="flex gap-4 items-center">
              <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
                <img src={dewImage} alt="" className="h-5" />
              </div>
              <div className="dark:text-white text-sm">
                <p className="">Create Dews</p>
                <p className="text-gray-400 text-[13px]">
                  Create a new Virtual Private Server (VPS)
                </p>
              </div>
            </Link>
            <Link to={"/domain"} className="flex gap-4 items-center">
              <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
                <img src={domain} alt="" className="h-5" />
              </div>
              <div className="dark:text-white text-sm">
                <p className="">Domain</p>
                <p className="text-gray-400 text-[13px]">
                  Get your domain hosting
                </p>
              </div>
            </Link>
            <a href="https://vantagecoops.com/" target="blank" className="flex gap-4 items-center">
              <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
                <img src={Cooperative} alt="" className="h-5" />
              </div>
              <div className="dark:text-white text-sm">
                <p className="">Co-operative Society Services</p>
                <p className="text-gray-400 text-[13px]">
                  Launch cooperative platform
                </p>
              </div>
            </a>
            <div className="flex gap-4 items-center">
              <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
                <img src={email} alt="" className="h-5" />
              </div>
              <div className="dark:text-white text-sm">
                <p className="">Email services</p>
                <p className="text-primaryBlue text-[13px]">Coming soon...</p>
              </div>
            </div>
            <Link to={"/loadBalancer"} className="flex gap-4 items-center">
              <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 h-16 flex items-center justify-center">
                <img src={loadBalancer} alt="" className="h-5" />
              </div>
              <div className="dark:text-white text-sm">
                <p className="">Create a Load Balancer</p>
                <p className="text-gray-400 text-[13px]">
                  Distribute traffic between multiple dews
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="grid gap-4 mb-10">
          <p className="dark:text-white text-sm">Upgrade:</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-sm">
            <div className="border rounded-md dark:border-[#c4c4c432] dark:text-white hover:bg-brandBlue/30 dark:hover:bg-brandBlue/5 cursor-pointer p-6 flex justify-between items-center">
              <span>Create additional volumes</span>
              <img src={arrow} alt="" />
            </div>
            <Link to={"/client-area/reservedIP"} className="border rounded-md dark:border-[#c4c4c432] dark:text-white hover:bg-brandBlue/30 dark:hover:bg-brandBlue/5 cursor-pointer p-6 flex justify-between items-center">
              <span>Setup reserved IPs</span>
              <img src={arrow} alt="" />
            </Link>
            <Link to={"/client-area/snapshot"} className="border rounded-md dark:border-[#c4c4c432] dark:text-white hover:bg-brandBlue/30 dark:hover:bg-brandBlue/5 cursor-pointer p-6 flex justify-between items-center">
              <span>Take a snapshot</span>
              <img src={arrow} alt="" />
            </Link>
            <div className="border rounded-md dark:border-[#c4c4c432] dark:text-white hover:bg-brandBlue/30 dark:hover:bg-brandBlue/5 cursor-pointer p-6 flex justify-between items-center">
              <span>Create a cloud firewall</span>
              <img src={arrow} alt="" />
            </div>
            {/* <div className="border rounded-md dark:border-[#c4c4c432] dark:text-white hover:bg-brandBlue/30 dark:hover:bg-brandBlue/5 cursor-pointer p-6 flex justify-between items-center">
              <span>Check dews metrics</span>
              <img src={arrow} alt="" />
            </div> */}
          </div>
        </div>
        </div>
     );
}
 
export default Resources;