import Accordion from "../faqAccordion";

const Email = () => {
    return ( 
        <div>
            <Accordion 
              Title={"What email services does Cloudvantage offer?"}
              Content={"Cloudvantage offers professional email hosting solutions that allow businesses to create custom email addresses using their domain name (e.g., you@yourdomain.com). Our email services include features such as ample storage space, spam filtering, virus protection, and webmail access for managing your emails from anywhere."}
            />
            <Accordion 
              Title={"Why choose Cloudvantage email over free email providers?"}
              Content={"Cloudvantage email offers greater control, security, and reliability compared to free email providers. You benefit from a professional brand image with custom email addresses, enhanced spam filtering, and guaranteed uptime."}
            />
            <Accordion 
              Title={"Is it easy to set up my business email with Cloudvantage?"}
              Content={"Yes! We provide step-by-step instructions and support to configure your email accounts with your domain name. You can access your emails through a user-friendly webmail interface or integrate them with your preferred email client software."}
            />
            <Accordion 
              Title={"Can I migrate my existing emails to Cloudvantage?"}
              Content={"We offer migration assistance to help you move your existing emails to your Cloudvantage email accounts. This ensures a smooth transition and uninterrupted communication for your business."}
            />
            <Accordion 
              Title={"What happens if I need help with my email account?"}
              Content={"Cloudvantage provides 24/7 customer support to assist you with any questions or technical issues you might encounter with your email service. Please send a mail to support@cloudvantage.com.ng or call +2347080704824 for more information"}
            />
            <Accordion 
              Title={"Do you offer any backup solutions for email data?"}
              Content={"Yes, Cloudvantage offers regular email backups to ensure your data is protected in case of unforeseen circumstances. You can also configure automatic backups for added peace of mind."}
            />
        </div>
     );
}
 
export default Email;