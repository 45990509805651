import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listDews } from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";

export function useListDews() {
    const [listDew, setListDews] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        const fetchListOfDew = async()=>{
            try{
                const { payload } = await dispatch(listDews({ navigate }));
                setListDews(payload);
            }
            catch(err){}
        }
        fetchListOfDew();
    }, [dispatch, navigate])
    return listDew;
}
