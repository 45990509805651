import pattern from "../../assets/images/bluePattern.svg"
import Input from "../../components/input";
import Select from "../../components/select";
import TextArea from "../../components/textArea";

const Support = () => {
  document.title = "Support - CloudVantage"
  const options = [
    { value: "all-services", label: "All services" },
    { value: "domain-hosting", label: "Domain Hosting" },
    { value: "vps", label: "Virtual Private Servers" },
    {
      value: "multipurpose-services",
      label: "Multipurpose Co-operative Society Services",
    },
    { value: "email-services", label: "Email services" },
  ];
  return (
    <div className="px-4 md:px-0 grid gap-4 lg:gap-16 pt-6 pb-10">
     <div className="bg-brandBlue rounded-md grid grid-cols-1 lg:grid-cols-5">
          <div className="lg:col-span-3 px-6 md:px-10 py-10 md:py-16">
            <p className="text-lg leading-snug crimson-font font-bold mb-4">Support.</p>
            <p className="text-sm">At CloudVantage, we offer the support and advice you need to turn your ideas into reality. When you're ready to establish your online presence, our team is equipped, trained, and eager to guide you from the beginning to success.</p>
          </div>
          <div className="lg:col-span-2 flex items-end md:h-32 lg:h-full overflow-clip">
            <img src={pattern} alt="" className="w-full"/>
          </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="md:col-span-8">
          <p className="text-lg dark:text-white leading-snug crimson-font font-bold mb-4">Report a problem</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end">
            <Select
              label="Filter services"
              name="services"
              options={options}
            />
            <Input label={"Full name"} />
            <Input label={"Phone number"} />
            <Input label={"Email address"} />
            <div className="md:col-span-2">
              <TextArea />
            </div>
            <div className="mt-2">
              <button className="bg-black dark:bg-[#1da1fc] text-white text-xs px-6 py-4 rounded-sm">
                Get started
              </button>
            </div>
          </div>
        </div>
        <div className="md:col-span-4">
          <div className="bg-white dark:bg-slate-800 rounded-lg p-1 relative overflow-hidden">
            <div className="rounded-lg overflow-hidden">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.1025944310345!2d3.356231673118372!3d6.634179121871163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93ead78764b7%3A0x3cec0e15b8754983!2sDele%20Omodara%20Cl%2C%20Omole%20Phase%201%2C%20Ojodu%2FAgidingbi%2FOmole%20101233%2C%20Lagos!5e0!3m2!1sen!2sng!4v1710317843022!5m2!1sen!2sng" style={{ border: 0, width: '100%', height:400 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="absolute bg-white dark:bg-slate-800 text-gray-500 p-4 bottom-0 text-sm right-0 left-0 py-6">
              <p className="mb-3">
                Address: 2A, Dele Omodara Close. <br />
                Omole Phase 1, Ikeja. <br />
                Lagos state.
              </p>
              <a href="https://maps.app.goo.gl/tXgcrf4Q6od3W8xn6" target="blank" className="text-[#1da1fc]">[Get directions]</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
