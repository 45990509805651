import Axios from "axios";
export const baseUrlNoAuth = Axios.create(
    {
        baseURL: 'https://cvgcoops.com.ng/api/v1/',
        headers: {'Content-Type': 'application/json'}
    }
)
export const baseUrlWithAuth = Axios.create(
    {
        baseURL: 'https://cvgcoops.com.ng/api/v1/',
        headers: {
            'Content-Type': 'application/json',
        },
    }
)
baseUrlWithAuth.interceptors.request.use(
    config => {
        const accessKey = JSON.parse(localStorage.getItem("userSessionData"))?.access;
        if (accessKey) {
            config.headers['Authorization'] = `Bearer ${accessKey}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);