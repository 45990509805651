import Button from "./button";
import pattern from "../assets/images/pricingPattern.svg"

const PricingCard = ({category, price, duration}) => {
    return ( 
        <div className="bg-white rounded-md">
          <div className="h-32 p-1 relative">
            <img src={pattern} alt="" className="h-full w-full object-cover rounded-md" />
            <div className="absolute bottom-0 left-0 text-[13px] p-3">
              <div className="bg-black/30 rounded-md backdrop-blur-md p-2 text-white">{category}</div>
            </div>
          </div>
          <div className="p-4 grid gap-6">
            <div>
              <span className="text-xs">Starts at:</span>
              <p className="font-semibold text-2xl crimson-font text-[#1da1fc]">NGN {price} <span className="text-sm font-normal text-black/50">/ {duration}</span></p>
            </div>
            <Button width={"w-full"} buttonText={"Buy now"} />
          </div>
        </div>
     );
}
export default PricingCard;