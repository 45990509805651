import Accordion from "../faqAccordion";

const VPN = () => {
    return ( 
        <div>
          <Accordion 
            Title={"What is a VPN, and why do I need one?"}
            Content={"A VPN (Virtual Private Network) encrypts your internet traffic and routes it through a secure server, protecting your online activity and data from unauthorized access. This is especially useful when using public Wi-Fi networks or bypassing geo-restrictions."}
          />
          <Accordion 
            Title={"Does Cloudvantage offer VPN services for personal and business use?"}
            Content={"Yes, Cloudvantage provides VPN services for both personal and business use. Our VPN solutions offer secure and private browsing, enhanced online security, and the ability to access geo-restricted content from anywhere in the world."}
          />
          <Accordion 
            Title={"Can I use Cloudvantage VPN on multiple devices?"}
            Content={"Yes, Cloudvantage VPN supports multiple simultaneous connections, allowing you to use the VPN on multiple devices simultaneously. Whether you're using a computer, smartphone, tablet, or even a smart TV, you can protect all your devices with Cloudvantage VPN."}
          />
          <Accordion 
            Title={"Does Cloudvantage VPN log my online activities?"}
            Content={"No, Cloudvantage VPN is committed to protecting your privacy and anonymity online. We have a strict no-logs policy, which means we do not monitor, log, or store any information about your online activities while connected to our VPN servers. Your privacy is our top priority."}
          />
        </div>
     );
}
 
export default VPN;