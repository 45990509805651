import Select from "../../../components/select";

const Snapshot = () => {
  document.title = "Take a snapshot - Cloudvantage";

  const options = [
    { value: "Select-dew", label: "Select a dew" },
    { value: "vantage-1", label: "Vantage Large" },
    { value: "vantage-2", label: "Vantage Medium" },
  ];

  return (
    <div>
      <p className="dark:text-white text-sm mb-1">Take a snapshot:</p>
      <p className="dark:text-gray-500 text-gray-400 text-sm mb-6 leading-loose md:leading-normal">A snapshot point-in-time backup of the entire virtual machine, including its data, system settings, and active processes. It allows for quick restoration in case of data loss or system issues, ensuring service continuity and security during updates or changes.</p>

      <div className="grid gap-4">
          <div className="grid grid-cols-1 md:grid-cols-2 items-end gap-4">
            <Select
              name="select dew"
              // value={selectedValue}
              // onChange={handleSelectChange}
              options={options}
            />
            <div>
                <input
                  type="text"
                  name=""
                  placeholder="Give your snapshot a name"
                  className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460]  active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
                />
            </div>
          </div>
          <div>
            <button className="p-4 rounded-md bg-primaryBlue text-white text-sm">
                Take snapshot
            </button>
          </div>
      </div>
    </div>
  );
};

export default Snapshot;
