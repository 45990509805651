import React, { useState, useEffect, useContext } from "react";
// import toggleOff from "../assets/svg-icons/toggleOff.svg";
// import toggleOn from "../assets/svg-icons/toggleOn.svg";
import Logoicon from "../assets/images/cloudVantage Logo.png";
import icon from "../assets/images/cloudVantage Icon.png";
import WhiteLogoicon from "../assets/images/cloudVantage Logo White.png";
import WhiteIcon from "../assets/images/cloudVantage Icon White.png";
import MenuIcon from "../assets/svg-icons/menuicon";
import { Link, useLocation } from "react-router-dom";
import user from "../assets/svg-icons/user.svg";
import ThemeToggler from "./themeToggler";
import { ThemeContext } from "./themeContext";

const Navbar = () => {
  const location = useLocation()
  const [menuVisible, setMenuVisible] = useState(false);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  // Function to close the menu
  const closeMenu = () => {
    setMenuVisible(false);
  };

 // Use theme context instead of local state
 const { theme } = useContext(ThemeContext);
 
  return (
    <div className="bg-white py-4 dark:bg-[#141D26] px-4 md:px-10 lg:px-[100px] border-b dark:border-b-[#c4c4c432] dark:text-white ">
      <div className="flex items-center justify-between">
        <Link to={"/"}>
          <img
            src={theme === "dark" ? WhiteIcon : icon}
            alt=""
            className="lg:hidden h-6"
          />
        </Link>
        <MenuIcon
          action={toggleMenu}
          colorTheme={theme === "dark" ? "#ffffff" : "#141D26"}
        />
      </div>
      <div
        className={`grid lg:flex justify-between border-t dark:border-t-slate-800 lg:border-0 mt-6 space-y-6 lg:space-y-0 lg:mt-0 items-center ${
          menuVisible ? "block" : "hidden"
        }`}
      >
        <div className="hidden lg:block">
          <Link to={"/"}>
            <img
              src={theme === "dark" ? WhiteLogoicon : Logoicon}
              alt=""
              className="h-[18px]"
            />
          </Link>
        </div>
        <div className="grid lg:flex gap-8 lg:gap-10 items-center">
          <div className="grid lg:flex lg:space-x-10 space-y-6 lg:space-y-0 text-[13px]">
          <Link to={"/contact"} onClick={closeMenu} className={`${location.pathname === "/contact" ? "text-primaryBlue font-semibold" : ""}`}>
              <span>Contact us</span>
            </Link>
            <Link to={"/support"} onClick={closeMenu} className={`${location.pathname === "/support" ? "text-primaryBlue font-semibold" : ""}`}>
             Support
            </Link>
            <Link to={"/pricing"} onClick={closeMenu} className={`${location.pathname === "/pricing" ? "text-primaryBlue font-semibold" : ""}`}>
              <span>Pricing</span>
            </Link>
            <a href="/#faq" onClick={closeMenu} className={`${location.pathname === "/pricing" ? "text-primaryBlue font-semibold" : ""}`}>
              <span>FAQs</span>
            </a>
            <Link to={"/about-us"} onClick={closeMenu} className={`${location.pathname === "/about-us" ? "text-primaryBlue font-semibold" : ""}`}>
              <span>About us</span>
            </Link>
          </div>
          <div className="grid lg:flex gap-6">
            <Link to={"/sign-in"} onClick={closeMenu} className="flex gap-2 items-center bg-white dark:bg-transparent md:dark:bg-[#C9E9FC] lg:bg-[#C9E9FC] py-0 lg:py-3 px-0 lg:px-4 rounded">
              <img src={user} alt="" className="hidden lg:block" />
              <span>
                <span className="text-sm lg:text-xs lg:text-[#1DA1F2] font-normal lg:font-medium">
                  My account
                </span>
              </span>
            </Link>

            <ThemeToggler />

            {/* <button onClick={handleThemeSwitch}>
              <div className="flex items-center gap-2">
                <img src={theme === "dark" ? toggleOn : toggleOff} alt="" />
                <span className="text-sm md:text-xs opacity-50">
                  Toggle theme
                </span>
              </div>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
