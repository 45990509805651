import Input from "../../../components/input";

const ResetPassword = () => {
    return ( 
        <div className="px-4 flex justify-center items-center py-8">
        {/* Form */}
        <div className="my-3 w-full md:w-[50%] lg:w-[40%] ">
          <form>
            <div className="bg-white dark:bg-[#141D26] border border-[#c4c4c432] dark:text-white rounded-md px-6 py-10 grid gap-6">
              <div>
                <p className="pb-1 crimson-font text-lg font-semibold">Reset password</p>
                <p className="text-xs text-[#c4c4c4] pb-2 border-b dark:border-b-[#c4c4c432]">
                  Regain access to your account
                </p>
              </div>
              <Input
                label={"New password"}
                type={"pasword"}
                isPassword={"true"}
                name={"emailAddress"}
              />
              <Input
                label={"Confirm password"}
                type={"pasword"}
                isPassword={"true"}
                name={"emailAddress"}
              />
              {/* Submit */}
              <div className="grid md:flex md:justify-between gap-6 md:gap-0 items-center">
                <button type="submit" className="text-sm md:text-xs text-white bg-[#1DA1F2] rounded px-8 py-4 cursor-pointer">
                  Reset password!
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
     );
}
 
export default ResetPassword;