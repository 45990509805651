import { useSelector } from "react-redux";
import { Link, Navigate, Outlet } from "react-router-dom";
// import UserInfo from "../components/userInfo";
// import Flavours from "../components/flavours";
import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../../../components/cartContext";
import whiteArrow from "../../../assets/svg-icons/whiteArrow.svg";
// import more from "../../../assets/svg-icons/more.svg";
// import box from "../../../assets/svg-icons/empty-box.svg";
import add from "../../../assets/svg-icons/add.svg";
import folder from "../../../assets/svg-icons/folder.svg";
import edit from "../../../assets/svg-icons/edit.svg";
import dewImage from "../../../assets/svg-icons/dewImage.svg";
import arrow from "../../../assets/svg-icons/blueArrow.svg";

const Home = () => {

  const { setCartNumber } = useContext(CartContext);
  const selectedProject = useSelector(state => state.user.selectedProject)

  // useEffect(() => {
  //   const now = new Date();
  //   const hour = now.getHours();

  //   if (hour > 0 && hour < 12) {
  //     setGreeting("Good morning");
  //   } else if (hour >= 12 && hour < 17) {
  //     setGreeting("Good afternoon");
  //   } else {
  //     setGreeting("Good evening");
  //   }
  // }, []);

  // const products = [
  //   {
  //     id: "vantage.giant",
  //     name: "Vantage Giant",
  //     price: 1000.0,
  //     quantity: 1,
  //     vcpu: "16",
  //     ramSize: "32 Gb",
  //     diskSize: "200 Gb",
  //   },
  //   {
  //     id: "vantage.huge",
  //     name: "Vantage Huge",
  //     price: 2000.0,
  //     quantity: 1,
  //     vcpu: "8",
  //     ramSize: "16 Gb",
  //     diskSize: "150 Gb",
  //   },
  //   {
  //     id: "vantage.large",
  //     name: "Vantage Large",
  //     price: 9000.0,
  //     quantity: 1,
  //     vcpu: "4",
  //     ramSize: "8 Gb",
  //     diskSize: "125 Gb",
  //   },
  //   {
  //     id: "vantage.medium",
  //     name: "Vantage Medium",
  //     price: 123000.0,
  //     quantity: 1,
  //     vcpu: "4",
  //     ramSize: "4 Gb",
  //     diskSize: "100 Gb",
  //   },

  //   {
  //     id: "vantage.small",
  //     name: "Vantage Small",
  //     price: 0.0,
  //     quantity: 1,
  //     vcpu: "2",
  //     ramSize: "4 Gb",
  //     diskSize: "75 Gb",
  //   },
  //   {
  //     id: "vantage.micro",
  //     name: "Vantage Micro",
  //     price: 0.0,
  //     quantity: 1,
  //     vcpu: "2",
  //     ramSize: "2 Gb",
  //     diskSize: "50 Gb",
  //   },
  //   {
  //     id: "vantage.nano",
  //     name: "Vantage Nano",
  //     price: 0.0,
  //     quantity: 1,
  //     vcpu: "1",
  //     ramSize: "1 Gb",
  //     diskSize: "25 Gb",
  //   },
  // ];

  document.title = "Client area - CloudVantage";

  // const fullName = userSessionData.fullname;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12">
      {/* <div className="lg:col-span-2 bg-white dark:bg-[#141D26] border-r dark:border-r-[#c4c4c432] p-6 text-sm dark:text-white hidden lg:block">
        <div className="">
          <div>
          </div>
          <div className="grid gap-3">
            <div className="flex items-center gap-2">
              <img src={folder} alt="" className="h-4 mb-[3px]" />
              <span className="text-gray-400">Projects [2]</span>
            </div>
            <div className="max-h-24 w-full overflow-y-scroll grid gap-3 text-[13px]">
              <p>Default project</p>
              <p>Project 1</p>
              <p>Project 2</p>
              <p>Default project</p>
            </div>
            <div className="flex gap-2 text-xs items-center bg-brandBlue text-primaryBlue p-3 rounded-md w-fit">
              <img src={add} alt="" className="h-4" />
              New project
            </div>
          </div>
          <div className="py-6 my-6 grid gap-4 border-y dark:border-y-[#c4c4c432]">
            <Link to={"/client-area"}>Resources</Link>
            <Link to={"/client-area/activity"}>Activity</Link>
            <Link to={"/client-area/manage"}>Manage project</Link>
          </div>
        </div>
      </div> */}
      <div className="px-4 md:px-10 py-6 lg:col-span-12 min-h-screen">
      <div className="grid md:flex gap-4 md:justify-between md:items-center pb-6">
          <div className="flex gap-3">
            <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 flex items-center justify-center">
              <img src={dewImage} alt="" className="h-5" />
            </div>
            <div className="dark:text-white text-sm">
              <p className="text-gray-500">{selectedProject ? selectedProject.name : ""}</p>
              <p className="mb-3">{selectedProject ? selectedProject.description: ""}</p>
              <div className="flex gap-2 items-center text-primaryBlue">
                <span>
                  <img src={edit} alt="" className="h-[18px]" />
                </span>
                <Link to={"/client-area/manage"}>Manage project</Link>
              </div>
            </div>
          </div>
          <div>
            <span className="w-fit border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center divide-x dark:divide-gray-500">
              <span className="text-xs text-[#1da1f2] cursor-default pe-2">
                Wallet balance: 0.00
              </span>
              <span className="ps-2">
                <img src={arrow} alt="" />
              </span>
            </span>
          </div>
      </div>
      <div className="border-t border-t-[#c4c4c460] pt-6">
        <Outlet />
      </div>
      </div>
    </div>
  );
};

export default Home;
