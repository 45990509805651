import React, { createContext, useEffect, useState } from "react";

// Create a Context for the theme
export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    // Initialize theme from local storage or determine based on time if not set
    const savedTheme = localStorage.getItem("theme");

    if (savedTheme) {
      return savedTheme;
    } else {
      const currentHour = new Date().getHours();
      return currentHour >= 18 || currentHour < 7 ? "dark" : "light";
    }
  });
  useEffect(() => {
    // Save theme to local storage whenever it changes
    localStorage.setItem("theme", theme);

    // Apply the theme class to the document
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
