
import close from "../assets/svg-icons/closeBrand.svg";
const Modal = ({title,isVisible,onClose, children}) => {
    if(!isVisible){
        return null;
    }
    return ( 
        <div className="w-full fixed h-screen bg-black/70 top-0 px-4 flex items-end md:items-center justify-center">
        <div className="bg-white dark:bg-[#141D26] rounded w-full md:w-[30%] dark:text-white max-h-[80%] mb-4 md:mb-0 overflow-auto">
          <div className="flex justify-between items-center text-sm p-6 border-b dark:border-b-[#c4c4c432]">
              <span className="dark:text-white">{title}</span>
            <img
              src={close}
              onClick={()=>{onClose()}}
              className="h-5 cursor-pointer"
              alt=""
            />
          </div>
          <div className="grid text-xs px-4 pb-4 pt-2">
            {children}
          </div>
        </div>
      </div>
     );
}
 
export default Modal;