import dewImage from "../../../assets/svg-icons/dewImage.svg";


const Activity = () => {
  document.title = "Activity - CloudVantage"

    const data = [
        { action: 'You added a dew to sample@example.com', date: 'Fri - March 18, 2024', time: '04:20' },
        { action: 'You renamed a project - sample@example.com', date: 'Sat - March 19, 2024', time: '11:11' },
        { action: 'You created a dew', date: 'Sun - March 20, 2024', time: '14:29' },
    ];

  return (
    <div>
      <div className="grid gap-2 mb-10">
          <p className="dark:text-white text-sm">Activity:</p>
          <div className="dark:text-white">
            <div className="bg-white dark:bg-slate-800 px-3 md:px-4 py-4 border dark:border-[#c4c4c432] border-b rounded-t-md text-sm hidden md:grid grid-cols-12 gap-4 font-semibold">
              <span className="col-span-7">Action</span>
              <span className="col-span-3">Date</span>
              <span className="col-span-2">Time</span>
            </div>
            <div className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-t-md md:rounded-t-none rounded-b-md dark:border-[#c4c4c432] border-t md:border-t-2 text-sm">
              {data.map((row, index) =>
              <div key={index} className="dark:border-[#c4c4c432] border-b last:border-b-0 py-5 md:py-4 rounded-t-md text-sm grid grid-cols-2 md:grid-cols-12 gap-4">
                <div className="col-span-2 md:col-span-7 flex gap-2 items-center">
                 <div className="hidden md:block"><img src={dewImage} alt="" className="h-5 w-5 mix-blend-luminosity"/></div>
                 <div>{row.action}</div>
                </div>
                <span className="col-span-1 md:col-span-3">{row.date}</span>
                <span className="col-span-1 md:col-span-2 text-end md:text-start">{row.time}</span>
              </div>
              )}
            </div>
          </div>
        </div>
    </div>
  );
};

export default Activity;
