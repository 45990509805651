import { useState } from "react";
import Input from "../../../components/input";

const Settings = () => {
  document.title = "Settings - Cloudvantage";

  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const data = [
    {
      name: "Oluwatobi Amusa",
      role: "Owner",
      status: "Joined",
      signInMethod: "Email+password",
    },
    {
      name: "Ibrahim Babangida",
      role: "Team member",
      status: "invite sent",
      signInMethod: "Email+password",
    },
  ];
  return (
    <div className="px-4 md:px-10 py-6 min-h-screen dark:text-white">
      <p className="crimson-font text-xl font-medium text-primaryBlue">Settings</p>
      <div className="w-full lg:w-[70%] my-10">
        <div className="flex items-end gap-3 mb-6">
          <div className="w-full">
            <Input 
                label={"Team name"}
                type={"text"}
                value={"Default team"}
                />
          </div>
          <div>
            <button className="bg-primaryBlue text-white text-sm px-6 py-5 rounded-md mt-3">
              Update
            </button>
          </div>
        </div>
        <div className="mb-6">
          <Input 
                label={"Team email address"}
                type={"text"}
                />
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2">
          <div className="grid">
             <Input 
                label={"Dew limit"}
                type={"text"}
                value={"25 Dews"}
                />
          </div>
          <p className="text-gray-500 mt-2 text-sm">
            Please contact admin to request an increase in Dew limit
          </p>
        </div>

        <hr className="my-10 dark:border-[#c4c4c432]" />

{/* INVITE MEMBERS */}

        <div className="">
          <p>Members (1)</p>
          <div className="my-4 flex justify-between gap-4">
            <input
              type="search"
              name=""
              value={searchText}
              onChange={handleSearchChange}
              placeholder="Search name"
              className="bg-white text-black p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
            />
            <span className="p-4 text-sm border-2 border-primaryBlue text-primaryBlue rounded-md w-fit">
              Invite members
            </span>
          </div>
          <div className="overflow-x-auto rounded-md border dark:border-[#c4c4c432]">
            <table class="w-full text-sm text-left overflow-hidden">
              <thead class="bg-white dark:bg-slate-800 px-3 md:px-4 border text-gray-400 dark:border-[#c4c4c432] border-b  text-sm font-semibold">
                <tr>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    No.
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Role
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-4 whitespace-nowrap">
                    Sign in method
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-slate-800 px-3 md:px-4 border rounded-b-md dark:border-[#c4c4c432] border-t-2 text-sm">
                {data
                  .filter((row) =>
                    row.name.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((row, index) => (
                    <tr
                      key={index}
                      className="dark:hover:bg-[#ffffff05] hover:bg-brandBlue/25 whitespace-nowrap"
                    >
                      <td className="text-start py-4 px-6">{index + 1}</td>
                      <td className="text-start py-4 px-6">{row.name}</td>
                      <td className="text-start py-4 px-6">{row.role}</td>
                      <td className="text-start py-4 px-6">{row.status}</td>
                      <td className="text-start py-4 px-6">
                        {row.signInMethod}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <hr className="my-10 dark:border-[#c4c4c432]" />

{/* SWITCH CURRENCY */}
<div>
<p>Switch currency:</p>
<p className="mt-4 text-sm">Product prices will be displayed in <span className="underline">Nigerian Naira (₦)</span></p>
<button className="bg-primaryBlue text-white text-sm px-6 py-5 rounded-md mt-3"> Switch to Dollar ($) </button>
</div>

<hr className="my-10 dark:border-[#c4c4c432]" />

{/* UPDATE PASSPWORD */}

        <div className="">
          <p>Update password:</p>
          <div className="grid gap-4">
            <div className="grid grid-cols-1 gap-4 mt-6 ">
              <div className="">
              <Input 
                label={"Old password"}
                type={"password"}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Input 
                label={"New password"}
                type={"password"}
                />
              </div>
              <div>
              <Input 
                label={"Confirm password"}
                type={"password"}
                />
              </div>
              <div className="text-sm text-gray-500">
                <p>Your password must contain:</p>
                <ul className="list-disc list-inside">
                  <li>at least 8 characters</li>
                  <li>at least 1 special character</li>
                  <li>at least 1 number</li>
                  <li>at least 1 uppercase alphabet</li>
                </ul>
              </div>
            </div>
          </div>
          <button className="bg-primaryBlue text-white text-sm px-6 py-5 rounded-md mt-6">
              Update password
            </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
