import React from "react";

const Select = ({ label, name, value, onChange, onBlur, options }) => {
  return (
    <div className="grid gap-1 text-sm dark:text-white">
      <span className="text-sm">{label}</span>
      <div className="relative w-full">
        <div>
          <select
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className="bg-white text-black w-full p-4 text-sm rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
          >
            {options.map((option, index) => (
              <option
                key={index}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Select;
