import { Link } from "react-router-dom";

const Button = ({buttonText, width, destination}) => {
    return ( 
        <button className={`bg-[#1DA1F2] text-white text-sm px-6 py-4 rounded-sm ${width}`}>
            <Link to= {destination}>
              {buttonText}
            </Link>
        </button>
     );
}
 
export default Button;