import { useState } from "react";
import arrow from "../assets/svg-icons/accordionArrow.svg"

const Accordion = ({ Title, Content }) => {
  const [open, setOpen] = useState(false);

  const toggleAccordion = () => {
    setOpen(!open);
  };

  return (
      <div className="border dark:border-[#BCE1FC32] dark:bg-[#ffffff05] dark:text-white rounded mb-2">
        <div className="flex justify-between p-4 cursor-pointer items-center gap-3" onClick={toggleAccordion} >
          <p className="font-bold crimson-font"> {Title} </p>
          <img src={arrow} alt="" className={`transform ${ open ? "rotate-180" : "rotate-0" } transition-transform`}/>
        </div>
        {open && (
          <div className="px-4 pb-4 text-sm">
            <p className=" leading-loose">{Content}</p>
          </div>
        )}
      </div>
  );
};

export default Accordion;

