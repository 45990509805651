import { Backdrop, CircularProgress } from "@mui/material";
import * as React from "react";
const Spinner = ({ loading }) => {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Spinner;
