import { createContext, useState, useEffect } from "react";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartNumber, setCartNumber] = useState(0);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartNumber(cart.length);
  }, []);

  return (
    <CartContext.Provider value={{ cartNumber, setCartNumber }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
