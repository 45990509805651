import { showErrorMessage } from "../constants";
import { baseUrlNoAuth, baseUrlWithAuth } from "./apiClient";

export class apiEndPoints {
  static extractError(error) {
    let extracted = [];
    if (error.isAxiosError) {
      if (error.response) {
        if (error.response.data && error.response.data.statusmessage) {
          extracted.push(error.response.data.statusmessage);
        } else {
          extracted.push("An unexpected Error occurred");
        }
      } else if (error.request) {
        extracted.push("Network Error Occurred");
      } else {
        extracted.push("An Unexpected Error Occurred");
      }
    } else {
      extracted.push(error.message || "An unexpected Error occurred");
    }
    extracted.forEach((errorMsg) => showErrorMessage(errorMsg));
  }

  static async userRegistration(data) {
    try {
      return baseUrlNoAuth.post("/users/register", data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async userSignIn(data) {
    try {
      return baseUrlNoAuth.post("/users/login/token", data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async userSignOut() {
    try {
      return baseUrlWithAuth.post("/users/logout");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listFlavours() {
    try {
      return baseUrlWithAuth.get("/openstack/flavors");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listUserProjects() {
    try {
      return baseUrlWithAuth.get("/openstack/projects");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async updateUserProjects(data) {
    try {
      return baseUrlWithAuth.put(`/openstack/projects/${data.projectId}`, data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async createProject(data) {
    try {
      return baseUrlWithAuth.post("openstack/projects/create-project", data);
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }

  static async listImages() {
    try {
      return baseUrlWithAuth.get("/openstack/images");
    } catch (error) {
      apiEndPoints.extractError(error);
      throw error;
    }
  }
}
