import { Link } from "react-router-dom";
import search from "../../assets/svg-icons/search.svg";

const SearchDomain = () => {
    document.title="Search domain - CloudVantage"
    return ( 
        <div className="min-h-screen pb-16">
          <div className="px-4 md:px-0">
          <div className="flex justify-between gap-4 mt-4">
            <input type="search"
            name=""
            id=""
            placeholder="Search for a domain..."
            className="bg-white text-black w-full p-4 text-sm rounded-sm border-0 active:outline-0 focus:outline-0 placeholder:opacity-50"
            />
            <Link to={"/search-domain"} className="">
              <button className="bg-[#1DA1F2] flex items-center gap-3 text-sm rounded-sm w-16 h-full md:px-6 md:w-full justify-center">
                <img src={search} alt="" className="md:hidden" />
                <span className="text-sm md:text-xs truncate text-center text-white hidden md:block">
                  Search domain
                </span>
              </button>
            </Link>
          </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 mt-8">
              <div>
                <div className="px-6 py-8 bg-white text-emerald-500 border border-t-4 border-t-emerald-300 rounded-md">
                  <div className="flex justify-center">
                  <span className="text-[10px] p-3 bg-emerald-300 text-white font-medium rounded-md">Your domain exists!</span>
                  </div>
                  <p className="mt-8 font-light text-center">Searcheddomain.com</p>
                  <div className="mt-3 text-center">
                    <span className="text-xs text-black/50">Price:</span>
                    <p className="font-medium">$23,000</p>
                  </div>
                  <div className="mt-6 flex justify-center">
                    <Link to={"/cart"} className="bg-emerald-500 text-white text-xs px-6 py-4 rounded-sm ">
                      Claim domain!
                    </Link>
                    {/* <div className="flex items-center text-sm text-black/50 mt-6 gap-2">
                      <img src={bulb} alt="" />
                      <p>Great domain names are usually 15 characters or less</p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-span-2 ms-0 lg:ms-6 mt-8 lg:mt-0">
                <p className="text-sm font-medium mb-4 px-6 dark:text-white">Alternative domains:</p>
                <div className="grid gap-1">
                  <div className="px-6 py-4 text-sm border-b dark:border-[#c4c4c424] hover:bg-white hover:rounded-t-md dark:hover:bg-white/10 dark:text-white flex justify-between items-end">
                    <div className="grid gap-1">
                      <p>altdomain.online</p>
                      <p className="text-emerald-500">$23,000</p>
                    </div>
                    <span className="text-emerald-500 underline">Claim domain!</span>
                  </div>
                  <div className="px-6 py-4 text-sm border-b dark:border-[#c4c4c424] hover:bg-white hover:rounded-t-md dark:hover:bg-white/10 dark:text-white flex justify-between items-end">
                    <div className="grid gap-1">
                      <p>altdomain.online</p>
                      <p className="text-emerald-500">$23,000</p>
                    </div>
                    <span className="text-emerald-500 underline">Claim domain!</span>
                  </div>
                  <div className="px-6 py-4 text-sm border-b dark:border-[#c4c4c424] hover:bg-white hover:rounded-t-md dark:hover:bg-white/10 dark:text-white flex justify-between items-end">
                    <div className="grid gap-1">
                      <p>altdomain.online</p>
                      <p className="text-emerald-500">$23,000</p>
                    </div>
                    <span className="text-emerald-500 underline">Claim domain!</span>
                  </div>
                  <div className="px-6 py-4 text-sm border-b dark:border-[#c4c4c424] hover:bg-white hover:rounded-t-md dark:hover:bg-white/10 dark:text-white flex justify-between items-end">
                    <div className="grid gap-1">
                      <p>altdomain.online</p>
                      <p className="text-emerald-500">$23,000</p>
                    </div>
                    <span className="text-emerald-500 underline">Claim domain!</span>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
     );
}
 
export default SearchDomain;