import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Layout = () => {
    return ( 
        <div className="grid content-between min-h-screen">
          <div>
            <div className="w-full"> <Navbar /> </div>
            <div className="md:px-10 lg:px-[100px]">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
     );
}

export default Layout;