import Accordion from "../faqAccordion";

const Domain = () => {
    return ( 
        <div>
          <Accordion 
            Title={"What is a domain name?"}
            Content={"A domain name is your website's address on the internet. It's what users type in their browsers to reach your website (e.g., [www.cloudvantage.com.ng]). With Cloudvantage, you can register your unique domain name and establish your online presence."}
          />
          <Accordion 
            Title={"What domain extensions do we offer?"}
            Content={"Cloudvantage offers a wide selection of domain extensions, including popular options like .com, .net, .org, and country-specific extensions like .ng. Choose the extension that best reflects your target audience and brand identity."}
          />
          <Accordion 
            Title={"How can i register a new domain name?"}
            Content={"Registering a domain name with Cloudvantage is simple. Visit our website, search for your desired domain name, and follow the easy signup process. We offer competitive pricing and domain management tools within your account"}
          />
          <Accordion 
            Title={"Do you offer domain management services?"}
            Content={"Yes, Cloudvantage offers domain management services. We can help you manage your domain name renewal, DNS settings, and privacy protection options."}
          />
          <Accordion 
            Title={"Can i transfer my existing domain name to Cloudvantage?"}
            Content={"Absolutely! If you already own a domain registered with another registrar, you can transfer it to Cloudvantage for management and renewal. Our support team can assist you with the transfer process and ensure a smooth transition. Please send a mail to support@cloudvantage.com.ng or call +2347080704824 for more information."}
          />
          <Accordion 
            Title={"What happens when my domain name registration expires?"}
            Content={"Cloudvantage sends you renewal reminders before your domain name expires. You can easily renew your domain registration within your account dashboard to maintain ownership and keep your website accessible."}
          />
        </div>
     );
}
 
export default Domain;