import PricingCard from "../../components/pricingCard";
import pattern from "../../assets/images/bluePattern.svg";

const Pricing = () => {
  document.title = "Pricing - Cloudvantage";
  return (
    <div className="px-4 md:px-0 py-6 grid gap-6">
      <div className="bg-brandBlue rounded-md grid grid-cols-1 lg:grid-cols-5">
        <div className="lg:col-span-3 px-6 md:px-10 py-10 md:py-16">
          <p className="text-lg leading-snug crimson-font font-bold mb-4">
            Affordable plans for service
          </p>
          <p className="text-sm">
          Explore our services and choose the one that fits your requirements. Our team is here to assist you with any questions or custom solutions you may need. Contact Us for more information or to get started with your chosen plan today!
          </p>
          <p className="text-gray-400 text-sm mt-4">*No card required</p>
        </div>
        <div className="lg:col-span-2 flex items-end md:h-32 lg:h-full overflow-clip">
          <img src={pattern} alt="" className="w-full" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <PricingCard
          category={"Virtual Private Servers (VPS)"}
          heading={"Domain Registration"}
          price={"0.00"}
          duration={"month"}
          body={
            "Register a unique domain name for your website that is easy to remember and relevant to your business. We offer a wide range of domain extensions to choose from, so you can find the perfect one for your needs."
          }
        />
        <PricingCard
          category={"Domain Hosting"}
          price={"0.00"}
          duration={"month"}
          body={
            "Register a unique domain name for your website that is easy to remember and relevant to your business. We offer a wide range of domain extensions to choose from, so you can find the perfect one for your needs."
          }
        />
        <PricingCard
          category={"Multipurpose Co-operative Society Services"}
          price={"0.00"}
          duration={"month"}
          body={
            "Register a unique domain name for your website that is easy to remember and relevant to your business. We offer a wide range of domain extensions to choose from, so you can find the perfect one for your needs."
          }
        />
        <PricingCard
          category={"Email Services"}
          price={"0.00"}
          duration={"month"}
          body={
            "Register a unique domain name for your website that is easy to remember and relevant to your business. We offer a wide range of domain extensions to choose from, so you can find the perfect one for your needs."
          }
        />
      </div>
    </div>
  );
};

export default Pricing;
