import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { apiEndPoints } from "../remote/apiEndPoints";
import { showErrorMessage, showSuccessMessage } from "../constants";
import { retrieveFromLocalStorage } from "../constants";

const initialState = {
  users: null,
  loading: false,
  error: null,
  isAuthenticated: false,
  selectedProject: null,
  images: [],
  ...retrieveFromLocalStorage([
    "userSessionData",
    "userProjectList",
    "selectedProject",
  ]),
};

const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, data);
};

export const registerUser = createAsyncThunk(
  "user/registration",
  async (userData, { rejectWithValue }) => {
    try {
      const userRegistrationAPI = await apiEndPoints.userRegistration(userData);
      const response = await userRegistrationAPI.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const userAuthenticate = createAsyncThunk(
  "user/userLogin",
  async (userData, { rejectWithValue }) => {
    try {
      const apiUserLogin = await apiEndPoints.userSignIn(userData);
      const response = await apiUserLogin.data;
      saveToLocalStorage("userSessionData", JSON.stringify(response));
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data });
    }
  }
);

export const listDews = createAsyncThunk(
  "user/ListDews",
  async ({ navigate } = {}, { rejectWithValue }) => {
    try {
      const apiListDews = await apiEndPoints.listFlavours();
      const response = await apiListDews.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data, navigate });
    }
  }
);

export const listUserProjects = createAsyncThunk(
  "user/ListUserProjects",
  async () => {
    try {
      const apiListUserProjects = await apiEndPoints.listUserProjects();
      const response = await apiListUserProjects.data;
      saveToLocalStorage("userProjectList", JSON.stringify(response.projects));
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserProject = createAsyncThunk(
  "user/updateProject",
  async ({ updateProjectData, navigate }, { rejectWithValue }) => {
    try {
      const apiUpdateProject = await apiEndPoints.updateUserProjects(
        updateProjectData
      );
      const response = await apiUpdateProject.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data, navigate });
    }
  }
);

export const createProject = createAsyncThunk(
  "user/CreateProject",
  async ({ createProjectData, navigate }, { rejectWithValue }) => {
    try {
      const apiCreateProject = await apiEndPoints.createProject(
        createProjectData
      );
      const response = await apiCreateProject.data;
      return response;
    } catch (error) {
      return rejectWithValue({ data: error.response.data, navigate });
    }
  }
);

export const listImages = createAsyncThunk("user/ListImages", async () => {
  try {
    const apiListImages = await apiEndPoints.listImages();
    const response = await apiListImages.data.images;

    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const userLogOut = createAsyncThunk("user/Logout", async () => {
  try {
    const apiUserLogout = await apiEndPoints.userSignOut();
    const response = await apiUserLogout.data;
    logOutSession();
    return response;
  } catch (error) {
    return error.response.data;
  }
});

const logOutSession = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("userSessionData");
  localStorage.removeItem("selectedProject");
};

const userSlice = createSlice({
  name: "user",
  reducers: {
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
      saveToLocalStorage("selectedProject", JSON.stringify(action.payload));
    },
  },
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          showSuccessMessage("Registration successful");
        } else {
          state.error = action.payload.statusmessage;
          showErrorMessage(action.payload.statusmessage);
        }
        state.loading = false;
      })
      .addCase(userAuthenticate.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          state.isAuthenticated = true;
          state.userSessionData = action.payload;
        } else {
          state.error = action.payload.message;
          showErrorMessage(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(userLogOut.fulfilled, (state) => {
        state.selectedProject = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.users = null;
      })
      .addCase(listUserProjects.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          state.userProjectList = action.payload.projects;
        }
        state.loading = false;
      })
      .addCase(updateUserProject.fulfilled, (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          showSuccessMessage("Project details updated successfully");
        } else {
          state.error = action.payload.statusmessage;
          showErrorMessage(action.payload.statusmessage);
        }
        state.loading = false;
      })
      .addCase(listImages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listImages.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(listImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to load images";
      })

      //Fulfilled with notification message
      .addMatcher(isAnyOf(createProject.fulfilled), (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
          showSuccessMessage(action.payload.statusmessage);
        } else {
          state.error = action.payload.statusmessage;
          showErrorMessage(action.payload.statusmessage);
        }
        state.loading = false;
      })
      //Fulfilled without notification message
      .addMatcher(isAnyOf(listDews.fulfilled), (state, action) => {
        if (action.payload.statuscode === "00") {
          state.users = action.payload;
        }
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(
          registerUser.pending,
          userAuthenticate.pending,
          listDews.pending,
          listUserProjects.pending,
          updateUserProject.pending,
          createProject.pending
        ),
        (state) => {
          state.loading = true;
          state.users = null;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          registerUser.rejected,
          userAuthenticate.rejected,
          listDews.rejected,
          listUserProjects.rejected,
          updateUserProject.rejected,
          createProject.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.users = null;
          const { data, navigate } = action.payload || {};
          let errorMessage =
            data?.message || data?.statusmessage || "Failed, Try Again";
          if (errorMessage === "Invalid token") {
            if (navigate) {
              navigate("/sign-out");
            }
            errorMessage = "Session timed out. Please sign in to continue.";
          }
          state.error = showErrorMessage(errorMessage);
        }
      );
  },
});
export const { setSelectedProject } = userSlice.actions;
export const userReducer = userSlice.reducer;
