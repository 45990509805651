import { Link } from "react-router-dom";
import info from "../../../assets/svg-icons/info.svg";
import Select from "../../../components/select";
import arrow from "../../../assets/svg-icons/defaultArrow.svg";

const LoadBalancer = () => {

    const options = [
        { value: "HTTP", label: "HTTP" },
        { value: "HTTPS", label: "HTTPS" },
      ];

    return (
      <div className="px-4 md:px-10 py-6 min-h-screen">
        <div className="grid md:flex justify-between gap-6">
          <div className="order-2 md:order-1">
            <p className="crimson-font text-xl text-primaryBlue">
              Create a load balancer
            </p>
            <p className="w-full md:w-[80%] dark:text-gray-500 text-gray-400 text-sm mb-6 leading-loose md:leading-normal">
              A load balancer distributes network or application traffic across
              multiple servers to optimize resource use, enhance performance,
              and ensure service reliability and availability
            </p>
          </div>
          <div className="order-1 md:order-2">
            <Link
              to={"/client-area"}
              className="text-xs px-3 py-2 rounded-md text-red-500 bg-red-200 grid w-fit"
            >
              Cancel
            </Link>
          </div>
        </div>

        <div className="dark:text-white mt-10 grid gap-10">
          <div className="w-full lg:w-3/5">
            <p className="font-semibold mb-2">
              VPC Network - [<span className="text-gray-500">default-nyc3</span>
              ]
            </p>
            <p className="text-gray-500">
              All resources created in this data center will be members of the
              same VPC network. They can communicate securely over their Private
              IP Addresses
            </p>
          </div>

          <div>
            <p className="font-semibold mb-2">Scaling configuration</p>
            <p className="text-gray-500 w-full lg:w-3/5">
              You can add additional nodesto this load balancer in order to
              increase the number of simultaneous connections, requests per
              second and new SSL connections per second.{" "}
              <span className="font-semibold text-gray-400">
                You can change the number of nodes at anytime
              </span>
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 mt-6">
              <div className="lg:col-span-3 order-2 lg:order-1">
                <div className="flex justify-between items-center p-4 md:p-6 border dark:border-[#c4c4c460] rounded-t-md">
                  <p>Number of nodes</p>
                  <input
                    type="number"
                    className="w-24 bg-white text-black p-4 text-xs rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
                  />
                </div>
                <div className="grid gap-6 border border-t-0 dark:border-[#c4c4c460] p-4 md:p-6 bg-[#c4c4c410] dark:bg-[#c4c4c405] text-sm">
                  <div className="flex justify-between gap-4">
                    <p>Simultaneous connections</p>
                    <p>20,000</p>
                  </div>
                  <div className="flex justify-between gap-4">
                    <p>Requests per second</p>
                    <p>20,000</p>
                  </div>
                  <div className="flex justify-between gap-4">
                    <p>SSL connections per second</p>
                    <p>500</p>
                  </div>
                  <div className="flex justify-between gap-4">
                    <p>High availability</p>
                    <p>Enabled</p>
                  </div>
                </div>
                <div className="p-4 md:p-6 text-sm border border-t-0 rounded-b-md dark:border-[#c4c4c460]">
                  <p>
                    Monthly cost: <span className="text-gray-500">NGN </span>
                    20,000
                  </p>
                  <p className="mt-1 text-xs text-primaryBlue">
                    <span className="text-primaryBlue/50">NGN </span>10,000 /
                    node
                  </p>
                </div>
              </div>
              <div className="lg:col-span-2 order-1 lg:order-2">
                <div className="bg-primaryBlue/10 rounded-md p-4 md:p-6">
                  <img src={info} alt="" className="h-5" />
                  <p className="text-sm font-medium mt-4 mb-1">
                    Not sure what size to choose?
                  </p>
                  <p className="text-sm leading-loose text-gray-500">
                    Start with 2 nodes for a production deployment, or one node
                    for testing. After creating the load balancer, monitor the
                    above metrics and scale up and down anytime as needed
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="w-full lg:w-3/5">
              <p className="font-semibold mb-2">Connect Dews</p>
              <p className="text-gray-500">
                You can connect Dews or a set of Dewsusing tags from any
                project.
              </p>
              <input
                type="search"
                className="mt-4 w-full md:w-1/3 bg-white text-black p-4 text-xs rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
                placeholder="Search for a Dew"
              />
            </div>
          </div>

          <div>
            <div className="w-full lg:w-4/5">
              <p className="font-semibold mb-2">Forwarding rules</p>
              <p className="text-gray-500">
                See how traffic will be routed from the Load Balancer to your
                Dews. At least one rule is required.
              </p>
              <div className="flex gap-4 border border-[#c4c4c460] border-b-0 p-4 rounded-md rounded-b-none bg-[#c4c4c420] dark:bg-[#c4c4c410] mt-6">
                <div className="w-[45%]">Load Balancer</div>
                <div className="w-[10%]"></div>
                <div className="w-[45%]">Droplet</div>
              </div>
              <div className="border rounded-md rounded-t-none border-[#c4c4c460] p-4 md:p-6">
                <div className="border-b border-b-[#c4c4c432] last:border-none">
                  <div className="flex gap-4">
                    <div className="w-[45%] grid grid-cols-1 md:grid-cols-2 gap-4">
                      <Select
                        label={"Protocol"}
                        name="protocol"
                        options={options}
                      />
                      <Select label={"Port"} name="port" options={options} />
                    </div>
                    <div className="w-[10%] flex justify-center items-center md:items-end md:pb-5">
                      <img src={arrow} alt="" className="h-5 md:h-4" />
                    </div>
                    <div className="w-[45%] grid grid-cols-1 md:grid-cols-2 gap-4">
                      <Select
                        label={"Protocol"}
                        name="protocol"
                        options={options}
                      />
                      <Select label={"Port"} name="port" options={options} />
                    </div>
                  </div>
                  <p className="text-red-500 text-right py-4">Delete</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="w-full lg:w-4/5">
              <p className="font-semibold mb-4">Finalize and create</p>
              <div className="grid grid-cols-1 md:grid-cols-2 items-end mb-6">
                <div>
                  <p>Choose a name:</p>
                  <p className="text-gray-500 text-sm">Loan balancer names must be unique and contain alphanumeric characters, dashes and periods only.</p>
                </div>
                <div>
                  <input
                  type="text"
                  className="mt-4 w-full bg-white text-black p-4 text-xs rounded-md border border-[#c4c4c460] active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs dark:bg-slate-800 dark:text-white"
                  placeholder="Load balancer name"
                />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 items-end">
                <div>
                  <p>Select a project</p>
                  <p className="text-gray-500 text-sm">Select an existing project for this load balancer to belong to.</p>
                </div>
                <div>
                  <Select name="select-project" options={options} />
                </div>
              </div>
            </div>
          </div>

            <div className="w-full md:w-4/5 mt-4">
              <button className="w-full bg-primaryBlue text-white p-4 text-sm font-medium rounded-md hover:bg-primaryBlue/60">
                Create load balancer
              </button>
            </div>
        </div>
      </div>
    );
}
 
export default LoadBalancer;